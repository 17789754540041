.banner {
  height: 520px;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 86px;
}
.banner-title {
  font-size: 88px;
  margin: 0;
  font-family: heavy;
  letter-spacing: -1px;
}
.banner-phrase {
  font-size: 36px;
  font-family: light;
  letter-spacing: -1px;
  margin-bottom: 18px;
}
.banner-button {
  width: 224px;
  height: 56px;
  background: rgba(24, 24, 66, 1);
  border: none;
  color: white;
  font-family: light;
  font-size: 20px;
  transition: 0.3s;
  cursor: pointer;
}
.banner-button:hover {
  background: rgba(0, 174, 239, 1);
}
/* 96, 94, 94; */

@media screen and (max-width: 978px) {
  .banner-title {
    font-size: 48px;
    line-height: 40px;
    margin: 0;
    font-family: heavy;
    letter-spacing: -1px;
  }
}
