@font-face {
  font-family: light;
  src: url("./assets/Avenir\ Light.ttf");
}
@font-face {
  font-family: heavy;
  src: url("./assets/Avenir\ Heavy.ttf");
}
@font-face {
  font-family: regular;
  src: url("./assets/Avenir\ Regular.ttf");
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  /* If you want to implement it in very old browser-versions */
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* The rule below is not implemented in browsers yet */
  -o-user-select: none;

  /* The rule below is implemented in most browsers by now */
  user-select: none;
  overflow: hidden;
}
#root {
  position: fixed;
  left: 0;
  width: 100%;
}
.hidden {
  display: none;
}
.App {
  text-align: center;
  position: relative;
  overflow: scroll;
  max-height: 100vh;
  max-width: 100vw;
  background: white;
  z-index: -10;
}
a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
.background {
  position: fixed;
  top: 86px;
  z-index: -1;
  width: 100%;
  background: rgba(0, 187, 255, 1);
  height: 520px;
}
.back-image {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: 0 -20px;
  opacity: 0.55;
}

@media screen and (max-width: 768px) {
  .back-image {
    object-position: 0;
  }
}
/* .slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0f0f0f;
  transform-origin: bottom;
}
.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0f0f0f;
  transform-origin: top;
} */
