header {
  height: 86px;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: light;
  justify-content: center;
  position: fixed;
  background: white;
  top: 0;
  z-index: 100;
}

.header-left {
  display: flex;
  align-items: center;
  margin-left: 12px;
}
.hl-image {
  height: 78px;
  width: 78px;
}
.hl-text {
  width: 160px;
  font-size: 20px;
  text-align: start;
  line-height: 20px;
}
.header-right {
  margin-right: 12px;
  display: flex;
  gap: 40px;
}
.hr-button {
  height: 52px;
  width: 52px;
  margin-right: 6px;
  border-radius: 12.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 8px;
  position: relative;
}
.hr-button > div {
  height: 4px;
  border-radius: 500px;
  background: #00aeef;
}
.hr-bmiddle {
  justify-content: center;
}
.hrb-top {
  transform: rotate(45deg);
  width: 16px;
  position: absolute;
  right: 7px;
  top: 19px;
}
.hrb-bottom {
  transform: rotate(-45deg);
  width: 16px;
  position: absolute;
  right: 7px;
  bottom: 19px;
}
.hr-button > img {
  width: stretch;
  height: stretch;
}
.header-menu {
  position: absolute;
  width: calc(100vw * 2);
  height: calc(100vw * 1.5);
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  z-index: -10;
  background: white;
  padding-top: 106px;
  padding-bottom: 40px;
  border-radius: 50%;
  border-bottom: 1px solid rgba(0, 174, 239, 0.125);
}
.hm-option {
  font-size: 20px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.hmo-image {
  height: 40px;
  width: 40px;
}
.hmo-image2 {
  margin-top: 40px;
  height: 60px;
  width: 60px;
}
@media screen and (max-width: 900px) {
  header {
    justify-content: space-between;
  }
}
