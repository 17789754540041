.products {
  position: relative;
  width: 100%;
  height: 640px;
  background: white;
  scroll-margin: 86px;
}

.products-image {
  position: absolute;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 0.34;
}
.group-products {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 12px;
  background: rgba(0, 174, 239, 0.13);
}
.product {
  position: relative;
  z-index: 10;
  height: 480px;
  width: 320px;
  background: white;
  overflow: visible;
}
.p-logo {
  position: absolute;
  top: -36px;
  left: 50%;
  transform: translate(-50%, 0);
  height: 84px;
  width: 84px;
  border: 3px solid rgba(0, 174, 239, 1);
  background: white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pl-image {
  height: 55px;
  width: 55px;
}
.p-title {
  font-family: heavy;
  margin-top: 88px;
  font-size: 20px;
  margin-bottom: 20px;
}
.p-text {
  font-size: 16px;
  font-family: light;
  letter-spacing: 0px;
  line-height: 28px;
  width: 248px;
  margin: auto;
}
.p-button {
  display: block;
  width: 142px;
  height: 40px;
  margin: auto;

  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);

  font-family: light;
  letter-spacing: 0px;
  color: white;
  background: rgba(0, 174, 239, 1);
  border: 1px solid rgba(0, 174, 239, 1);
  padding: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: 0.3s;
}
.p-button:hover {
  color: rgba(0, 174, 239, 1);
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 174, 239, 1);
}

@media screen and (max-width: 1000px) {
  .products {
    position: relative;
    width: stretch;
    /* padding: 0 20px; */
    height: fit-content;
    background: white;
    scroll-margin: 86px;
  }
  .group-products {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    gap: 48px;
    padding: 48px 20px;
  }
  .product {
    position: relative;
    z-index: 10;
    /* height: 480px; */
    /* width: 320px; */
    height: fit-content;
    padding-bottom: 100px;
    width: stretch;
    max-width: 600px;
    background: white;
  }
  .p-text {
    font-size: 16px;
    font-family: light;
    letter-spacing: 0px;
    line-height: 28px;
    width: stretch;
    /* width: 248px; */
    margin: auto;
    padding: 0 20px;
  }
}
