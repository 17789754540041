.popup {
  position: absolute;
  left: 0px;
  bottom: 0px;
  top: -86px;
  z-index: 75;
  width: 100vw;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.63);
}
.pop-container {
  background: white;
  border-radius: 20px;
  z-index: 100;
  filter: blur(0);
  width: 37.5%;
  height: fit-content;
  padding: 20px;
}
.pop-title {
  font-family: regular;
  font-size: 36px;
}
.pop-text {
  font-family: light;
  font-size: 18px;
}
.pop-button {
  display: block;
  width: 142px;
  height: 40px;
  margin: auto;
  margin-top: 28px;

  font-family: light;
  letter-spacing: 0px;
  font-size: 18px;
  color: white;
  background: rgba(0, 174, 239, 1);
  border: 1px solid rgba(0, 174, 239, 1);
  padding: auto;
  transition: 0.3s;
  cursor: pointer;
}
.pop-button:hover {
  color: rgba(0, 174, 239, 1);
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 174, 239, 1);
}
