.propos {
  background: white;
  padding: 28px 0;
}
.propos-title {
  font-size: 60px;
  margin-bottom: 28px;
  font-family: light;
  letter-spacing: -1px;
}
.propos-text {
  width: 710px;
  text-align: center;
  margin: auto;
  font-family: light;
  letter-spacing: 0px;
  line-height: 28px;
}

@media screen and (max-width: 768px) {
  .propos-text {
    width: stretch;
  }
  .propos {
    background: white;
    padding: 28px 20px;
  }
}
