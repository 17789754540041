.footer {
  width: 100%;
  height: 160px;
  background: #f0f0f0;
  color: black;
  border-top: 0.5px solid rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.footer-top {
  display: flex;
  justify-content: space-between;
  /* margin: auto; */
  height: fit-content;
}
.ft-link {
  font-size: 14px;
  font-family: light;
  letter-spacing: 0px;
}
.footer-bottom {
  /* margin: auto; */
  font-size: 14px;
  font-family: light;
  letter-spacing: 0px;
  text-align: start;
}
